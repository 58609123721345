import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar, Badge,Modal,Form,Input,message } from 'antd'
import axios from 'axios'
import url from '../../../../pages/config'
import styles from './style.module.scss'

@Form.create()
@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    count: 7,
    updatePasswordModalVisible:false
  }

  logout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  changePassword=()=>{
    this.setState({ updatePasswordModalVisible: true })
  }

  submitPasswordValues = () => {
    const { form } = this.props
    const self = this
    form.validateFieldsAndScroll(
      ['oldPassword', 'newPassword', 'confirmPassword'],
      (err, values) => {
        if (!err) {
          const localId = window.localStorage.getItem('localId')
          const upsertPasswordMutation = {
            query: `mutation{
              updateNTUserPassword (
                userId :"${localId}",
                oldPassword : "${values.oldPassword}",
                newPassword:"${values.newPassword}",
                confirmPassword:"${values.confirmPassword}"
              ) {
                status
                message
              }
            }`,
          }
          axios({
            url: url.URL,
            method: 'POST',
            async: true,
            crossDomain: true,
            data: upsertPasswordMutation,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('notonToken')}`
            },
          })
            .then(response => {
              // console.log('Response on Update Menu is===>', response.data.data)
              if (response.data.data.updateNTUserPassword.status === 'Success') {
                message.success(response.data.data.updateNTUserPassword.message)
                self.setState({ updatePasswordModalVisible: false })
                form.resetFields()
              } else {
                message.error(response.data.data.updateNTUserPassword.message)
              }
            }).catch(errMessage => {
              const error = JSON.parse(JSON.stringify(errMessage))
              if (
                error.message === 'Request failed with status code 401' ||
                error.message === 'Network error: Unexpected token < in JSON at position 0'
              ) {
                this.logout()
              }
            })
        }
      },
    )
  }

  closePasswordPopup=()=>{
    const { form } = this.props
    this.setState({ updatePasswordModalVisible: false })
    form.resetFields()
  }

  render() {
    // const { user } = this.props
    const{updatePasswordModalVisible}=this.state;
    const { form } = this.props
    const { getFieldDecorator } = form
    const userName = localStorage.getItem('userName')
    const email = localStorage.getItem('emailId')
    const phone = localStorage.getItem('phone')
    // const roleName = localStorage.getItem('roleName')
    // console.log("===>email is<=====",email)
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {userName}
          </strong>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            Admin
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {email==="null"||email===undefined||email===null?'':email}
            <br />
            <strong>
              <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
            </strong>
            {phone==="null"||phone===undefined||phone===null?'':phone}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={this.changePassword}>
          <i className={`${styles.menuIcon} fe fe-user`} />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="#" onClick={this.logout}>
            <i className={`${styles.menuIcon} fe fe-log-out`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <div>
        <Dropdown overlay={menu} trigger={['click']}>
          <div className={styles.dropdown}>
            <Badge>
              <Avatar className={styles.avatar} shape="circle" size="small" icon="user" />
            </Badge>
          </div>
        </Dropdown>

        <div>
          <Modal
            title="Update Password"
            centered
            width="700px"
            visible={updatePasswordModalVisible}
            onOk={this.submitPasswordValues}
            onCancel={this.closePasswordPopup}
          >
            <Form layout="vertical">
              <div className="row">
                <div className="col-lg-4">
                  <Form.Item label="Old Password">
                    {getFieldDecorator('oldPassword', {
                      rules: [{ required: true, message: 'Please enter old password' }],
                    })(<Input.Password size="small" />)}
                  </Form.Item>
                </div>

                <div className="col-lg-4">
                  <Form.Item label="New Password">
                    {getFieldDecorator('newPassword', {
                      rules: [{ required: true, message: 'Please enter new password' }],
                    })(<Input.Password size="small" />)}
                  </Form.Item>
                </div>

                <div className="col-lg-4">
                  <Form.Item label="Confirm Password">
                    {getFieldDecorator('confirmPassword', {
                      rules: [{ required: true, message: 'Please re-enter new password' }],
                    })(<Input.Password size="small" />)}
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    )
  }
}

export default ProfileMenu
