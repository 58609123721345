import { notification } from 'antd'
import axios from 'axios';
import url from '../pages/config'

// console.log("======>URL is<======",url)

export async function login(email, password) {
 const getConversionUrl =`${url.Auth}?grant_type=password&username=${email}&password=${password}`
 // console.log("======>getConversionUrl<======",getConversionUrl)
  window.localStorage.setItem('flag', false);
  window.localStorage.setItem('email','');

  let flag = false;
   await axios({
    method:'POST',  
    url: getConversionUrl,
    headers: {'Content-Type': "application/x-www-form-urlencoded",'Authorization':'Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=','applicationId':'NOTON'},  
   }).then((response)=> {
    // console.log("======get Login response ======>>>>> ", response.data);
    if (response.data !=='' || response.data!==undefined || response.data!==null) {    
     // console.log("Inside Success Data====>")   
     const accessToken = response.data.access_token
     // console.log("=========>Access Token<=========",accessToken)
     window.localStorage.setItem('notonToken', accessToken);   
     flag = true;
     window.localStorage.setItem('flag', true);
     window.localStorage.setItem('email',email); 
     const getUserMutation = {
      query: `query {
        getUserDetails(username: "${email}")
        }`,
    }
    axios({
      url: url.URL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: getUserMutation,
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${accessToken}`
        }
    }).then(responseForUser => {
      // console.log("=======>responseForUser<========",responseForUser.data.data.getUserDetails)
      const userDetails = JSON.parse(responseForUser.data.data.getUserDetails)
      // console.log("===>userDetails<===",userDetails)
      const userIdFromServer = userDetails.userId;
      const userNameFromServer = userDetails.userName;
      const emailFromServer = userDetails.email
      const phoneFromServer = userDetails.phone
      const roleNameFromServer = userDetails.rolename
      // console.log("===>userIdFromServer<===",userIdFromServer)
      
      window.localStorage.setItem('localId',userIdFromServer)
      window.localStorage.setItem('userName',userNameFromServer)
      window.localStorage.setItem('emailId',emailFromServer)
      window.localStorage.setItem('phone',phoneFromServer)
      window.localStorage.setItem('roleName',roleNameFromServer)
      window.location.reload()
    })

   }else{
    notification.warning({
      message: "Error",
      description: "Invalid Login Credentials!!",
    })
  }
}).catch((e) => {
      // console.log("=====Credentials are=====",e.response)
      if(e.response.status===400){
        notification.warning({
          message:  e.response.data.error_description,
          description: "Check UserName Or Password",
         }) 
      }
      if(e.response.status===500){
        notification.warning({
          message: "Network Error",
          description:"Contact To Infra Team",
         }) 
      }
  
  });  
  return flag;
}

export async function currentAccount() {
  const obj = {
    email:window.localStorage.getItem('email'),
    authorized:window.localStorage.getItem('flag'),
    role: window.localStorage.getItem('role')
  }
  return obj;
}

export async function logout() {
  window.localStorage.removeItem('flag');
  window.localStorage.removeItem('role');
  window.localStorage.removeItem('localId')
  window.localStorage.removeItem('userName')
  window.localStorage.removeItem('email')
  window.localStorage.removeItem('phone')
  window.localStorage.removeItem('roleName')
  return true 
}