export default async function getMenuData() {

  /* let menuDataResponse = null;
  const menuDataMutation = {
    query: `query {
    notonMenuList
  }`

  }
  axios({
    url: url.URL,
    method: 'POST',
    async: true,
    crossDomain: true,
    data: menuDataMutation,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => {
    menuDataResponse = JSON.parse(response.data.data.notonMenuList)

  }) */
  // console.log("====>menuDataResponse<====",menuDataResponse)

   // return menuJSON




  /* return [
    {
      title: 'Home',
      key: 'home',
      url: '/noton/homemenu',
    },
    {
      title: 'My NOTON',
      key: 'mynoton',
      children: [
        {
          title: 'Pojo Approval',
          key: 'managechangeset',
          url: '/noton/managechangeset',
        },
        {
          title: 'Change Set',
          key: 'managechangeset',
          url: '/noton/managechangesetworkflow',
        },
        {
          title: 'My Changes',
          key: 'mychanges',
          url: '/noton/mychanges',
        },
        {
          title: 'My Approvals',
          key: 'myapprovals',
          url: '/noton/myapprovals',
        },
        {
          title: 'Dashboard',
          key: 'dashboard',
          url: '/noton/managedashboard/dashboardlist',
        },
        {
          title: 'Batch Register',
          key: 'batchregister',
          url: '/noton/managebatchregister/batchRegisterList',
        },
        {
          title: 'Application',
          key: 'application',
          url: '/noton/manageapplication/manageapplicationlist',
        },
        {
          title: 'Alerts',
          key: 'alerts',
          url: '/noton/managealerts/alertslist',
        },
        {
          title: 'NOTON Screens',
          key: 'notonscreens',
          url: '/noton/managenotonscreens',
        },
        {
          title: 'NOTON Menu',
          key: 'notonmenu',
          url: '/noton/managenotonmenu',
        },
        {
          title: 'NOTON Section',
          key: 'section',
          url: '/noton/managesection',
        },
      ],
    },

    {
      title: 'Project Mgmt',
      key: 'projectMgmt',
      children: [
        {
          title: 'Specifications',
          key: 'specifications',
          url: '/noton/projectmgmt/specifications',
        },
        {
          title: 'Tasks',
          key: 'tasks',
          url: '/noton/projectmgmt/tasks',
        },
        {
          title: 'Documents',
          key: 'documents',
          url: '/noton/projectmgmt/documents',
        },
        {
          title: 'Project Plan',
          key: 'projectplan',
          url: '/noton/projectmgmt/projectplan',
        },
        {
          title: 'Features',
          key: 'features',
          url: '/noton/projectmgmt/features',
        },
      ],
    },

    {
      title: 'Developer',
      key: 'developer',
      children: [
        {
          title: 'Tables',
          key: 'managetables',
          url: '/noton/managetables',
        },
        {
          title: 'Windows',
          key: 'managewindows',
          url: '/noton/windowslist',
        },
        {
          title: 'Menu',
          key: 'managemenus',
          url: '/noton/managemenu/menuDetailsInGrid',
        },
        {
          title: 'Process',
          key: 'manageprocess',
          url: '/noton/manageprocess/processDetailsInGrid',
        },
        {
          title: 'Messages',
          key: 'managemessages',
          url: '/noton/managemessages',
        },
        {
          title: 'Field Group',
          key: 'managefieldgroup',
          url: '/noton/managefieldgroup',
        },
        {
          title: ' Base Reference',
          key: 'managebasereference',
          url: '/noton/managebasereference',
        },
        {
          title: 'Reference',
          key: 'managereference',
          url: '/noton/managereference',
        },
        {
          title: 'Default Column',
          key: 'managedefaultcolumns',
          url: '/noton/managedefaultcolumns',
        },
        {
          title: 'SQL Query Tool',
          key: 'sqlquerytool',
          url: '/noton/sqlquerytool',
        },
        {
          title: 'Stored Procedure',
          key: 'storedprocedure',
          url: '/noton/storedprocedure/storedproceduresdetailsingrid',
        },
        {
          title: 'Module',
          key: 'managemodule',
          url: '/noton/managemodule',
        },
        {
          title: 'Report',
          key: 'managereport',
          url: '/noton/managereport',
        },
        {
          title: 'Auto Complete',
          key: 'manageautocomplete',
          url: '/noton/manageautocomplete',
        },
      ],
    },

    {
      title: '360 Developer',
      key: '360 developer',
      children: [
        {
          title: 'Menu',
          key: 'manage360menu',
          url: '/noton/360developer/360MenuDetailsInGrid',
        },
        {
          title: 'Admin Menu',
          key: 'adminmenu',
          url: '/noton/360developer/360AdminMenuDetails',
        }
      ],
    },

    {
      title: 'DevOps',
      key: 'devops',
      children: [
        {
          title: 'PgBadger',
          key: 'pgbadger',
        },
        {
          title: 'Server Status',
          key: 'serverstatus',
          url: '/noton/devops/serverstatus',
        },
        {
          title: 'Code Quality',
          key: 'codequality',
          url: '/noton/devops/codequality',
        },
        {
          title: 'Pipelines',
          key: 'pipelines',
          url: '/noton/devops/pipelines',
        },
        {
          title: 'Deployment Jobs',
          key: 'deploymentjobs',
          url: '/noton/devops/deploymentjobs',
        },
      ],
    },

    {
      title: 'Gitlab',
      key: 'gitlab',
      children: [
        {
          title: 'Repository',
          key: 'repository',
          url: '/noton/gitlab/repository',
        },
        {
          title: 'Issues',
          key: 'issues',
          url: '/noton/gitlab/issues',
        },
        {
          title: 'Snippets',
          key: 'snippets',
          url: '/noton/gitlab/snippets',
        },
      ],
    },

    {
      title: 'Client',
      key: 'client',
      children: [
        {
          title: 'Clients',
          key: 'clients',
          url: '/noton/manageclient/clientDetailsInGrid',
        },
      ],
    },

    {
      title: 'Licensing',
      key: 'licensing',
      children: [
        {
          title: 'Licence Manager',
          key: 'licencemanager',
          url: '/noton/licensing/licencemanager',
        },
      ],
    },

    {
      title: 'Logs',
      key: 'logs',
      children: [
        {
          title: 'Core GraphQL',
          key: 'coregraphql',
          url: '/noton/logs/coregraphql',
        },
        {
          title: '360 GraphQL',
          key: '360graphql',
          url: '/noton/logs/360graphql',
        },
        {
          title: 'Supply Chain GraphQL',
          key: 'supplychaingraphql',
          url: '/noton/logs/supplychaingraphql',
        },
        {
          title: 'Desktop POS',
          key: 'desktoppos',
          url: '/noton/logs/desktoppos',
        },
        {
          title: 'Mobile POS',
          key: 'mobilepos',
          url: '/noton/logs/mobilepos',
        },
        {
          title: 'Mobile Warehouse',
          key: 'mobilewarehouse',
          url: '/noton/logs/mobilewarehouse',
        },
        {
          title: 'ERP Front End',
          key: 'erpfrontend',
          url: '/noton/logs/erpfrontend',
        },
        {
          title: '360 Front End',
          key: '360frontend',
          url: '/noton/logs/360frontend',
        },
      ],
    },

    {
      title: 'Admin',
      key: 'admin',
      children: [
        {
          title: 'Roles',
          key: 'roles',
          url: '/noton/admin/roles',
        },
        {
          title: 'Users',
          key: 'users',
          url: '/noton/admin/users',
        },
        {
          title: 'Access Control',
          key: 'accesscontrol',
          url: '/noton/admin/accesscontrol',
        },
      ],
    },

    {
      title: 'More...',
      key: 'more',
      children: [
        {
          title: 'Preference',
          key: 'preference',
          url: '/noton/preference',
        },
        {
          title: 'Peformance',
          key: 'performance',
          children: [
            {
              title: 'ERP Server',
              key: 'erpserver',
              url: '/noton/performance/erpserver',
            },
            {
              title: 'Authentication Server',
              key: 'authenticationserver',
              url: '/noton/performance/authserver',
            },
            {
              title: '360 Server',
              key: '360server',
              url: '/noton/performance/360server',
            },
          ],
        },

        {
          title: 'Testing',
          key: 'testing',
          children: [
            {
              title: 'GraphQL-Test Cases',
              key: 'graphqltestcases',
              url: '/noton/testing/graphqltestcase',
            },
            {
              title: 'React JS-Test Cases',
              key: 'reactjstestcases',
              url: '/noton/testing/reactjstestcase',
            },
          ],
        },

        {
          title: 'Settings',
          key: 'settings',
          url: '/noton/settings',
        },
      ],
    },


  ] */
}