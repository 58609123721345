import React from 'react'
import {BellOutlined,SettingOutlined} from '@ant-design/icons'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {Select} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import classNames from 'classnames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import serviceUrl from '../../../pages/config/index'
import UserMenu from '../TopBar/UserMenu'
import style from './style.module.scss'

const { Option } = Select


const mapStateToProps = ({ /* menu, */ settings }) => ({
  // menuData: menu.menuData,
  settings,
  flyoutActive: !settings.isMobileView,
})

@withRouter
@connect(mapStateToProps)
class MenuTop extends React.Component {
  state = {
    activeSubmenu: '',
    activeItem: '',
    renderedFlyoutItems: {},
    menuDataFromService: [],
    menuDataInState:[]
  }

  flyoutTimers = {}

  currentLocation = ''

  componentWillMount = () => {
    const menuDataMutation = {
      query: `query {
      notonMenuList
    }`
  
    }
    axios({
      url: serviceUrl.URL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: menuDataMutation,
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('notonToken')}`}
    }).then(response => {
      const menuDataResponse = JSON.parse(response.data.data.notonMenuList)
      const sortedMenu = menuDataResponse.sort((a, b)=> {
        return a.seqno - b.seqno
      }) 
      for (let index = 0; index < sortedMenu.length; index+=1) {
        const element = sortedMenu[index].children;
        element.sort((a, b)=> {
          return a.seqno - b.seqno
        })         
      }
      this.setState({ menuDataFromService: sortedMenu })

    }).catch(err => {
      // console.log("===>Err<====",err)
      const error = JSON.parse(JSON.stringify(err))
      // console.log("===>Error is error<====",error.message)
      if(error.message==='Request failed with status code 401' || error.message==='Network error: Unexpected token < in JSON at position 0'){
        // console.log("=========>Inside error if<==========")
        this.logout();
       // const { history } = this.props
       // history.push('/system/login')
      }
      /* const error = JSON.parse(JSON.stringify(err))
      const { message } = error
      if (
        message === 'Network error: Unexpected token < in JSON at position 0' || message === 'Request failed with status code 401'
      ) {
        const { history } = this.props
        history.push('/system/login')
      } */
    })
  }

  /* componentDidMount() {
    this.setActiveItems(this.props)
  } */

  componentWillReceiveProps(newProps) {
    const { pathname } = newProps.location
    if (this.currentLocation !== pathname) {
      this.setActiveItems(newProps)
      this.currentLocation = pathname
    }
  }

  logout = () => {
    // e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  toggleSettings = () => {
    const { dispatch, settings } = this.props
    const { isSidebarOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      },
    })
  }

  toggleMenu = () => {
    const { dispatch, settings } = this.props
    const { isMenuCollapsed } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
  }

  toggleMobileMenu = e => {
    e.preventDefault()
    const { dispatch, settings } = this.props
    const { isMobileMenuOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  handleSubmenuClick = (e, key) => {
    console.log("==>abc<====",e,key)
    e.preventDefault()
    const { activeSubmenu } = this.state
    const { flyoutActive } = this.props
    if (flyoutActive) {
      return
    }
    this.setState({
      activeSubmenu: activeSubmenu === key ? '' : key,
    })
  }

  handleFlyoutOver = (event, key, items) => {
    const { flyoutActive } = this.props
    if (flyoutActive) {
      clearInterval(this.flyoutTimers[key])
      const item = event.currentTarget
      const itemDimensions = item.getBoundingClientRect()
      const element = this.renderFlyoutMenu(items, key, itemDimensions)
      this.setState(state => ({
        renderedFlyoutItems: {
          ...state.renderedFlyoutItems,
          [key]: element,
        },
      }))
    }
  }

  handleFlyoutOut = key => {
    const { flyoutActive } = this.props
    if (flyoutActive) {
      this.flyoutTimers[key] = setTimeout(() => {
        this.setState(state => {
          delete state.renderedFlyoutItems[key]
          return {
            renderedFlyoutItems: {
              ...state.renderedFlyoutItems,
            },
          }
        })
      }, 100)
    }
  }

  handleFlyoutConteinerOver = key => {
    clearInterval(this.flyoutTimers[key])
  }

  renderFlyoutMenu = (items, key, itemDimensions) => {
    const { settings } = this.props
    const { activeItem } = this.state
    const left = `${itemDimensions.left + itemDimensions.width / 2}px`
    const top = `${itemDimensions.top + itemDimensions.height}px`

    return (
      <div
        style={{ left, top }}
        className={classNames(style.air__menuFlyout, {
          [style.air__menuFlyoutTop]: settings.menuLayoutType === 'top',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
        })}
        key={key}
      >
        <ul
          className={style.air__menuTop__list}
          onMouseEnter={() => this.handleFlyoutConteinerOver(key)}
          onMouseLeave={() => this.handleFlyoutOut(key)}
           
          
        >
          {items.map(item => {
            return (
              <li
                className={classNames(style.air__menuTop__item, {
                  [style.air__menuTop__item__active]: activeItem === item.key,
                })}
                onClick={()=>this.subMenuClick(item)}
                key={item.key}
                role="presentation"
              >
                <Link to={item.url} className={style.air__menuTop__link}>
                  {item.icon && <i className={`${item.icon} ${style.air__menuTop__icon}`} />}
                  <span>{item.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  setActiveItems = props => {
    // console.log("===>Props<=====",props)
    /* const { menuData = [] } = props */
    const {menuDataFromService} = this.state
    if (!menuDataFromService.length) {
      return
    }
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const activeItem =
      _.find(flattenItems(menuDataFromService, 'children'), ['url', props.location.pathname]) || {}
    const activeSubmenu = menuDataFromService.reduce((key, parent) => {
      if (Array.isArray(parent.children)) {
        parent.children.map(child => {
          if (child.key === activeItem.key) {
            key = parent
          }
          return ''
        })
      }
      return key
    })
    this.setState({
      activeItem: activeItem.key,
      activeSubmenu: activeSubmenu.key,
    })
  }

  subMenuClick=(key)=>{
    if(key.title==="PgBadger"){
      window.open('https://tserv01.exceloid.com/pgbadger-preprod.html', '_blank');
    }
  }

  generateMenuItems = () => {
    /* const { menuData = [] } = this.props */
    const{menuDataFromService} = this.state
    const { activeSubmenu, activeItem } = this.state

    // console.log("activeSubmenu==>",activeSubmenu,activeItem)
    const menuItem = item => {
      // alert("===>menu item<===")
      
      const { key, title, icon, url } = item

      if (item.category) {
        return null
      }
      return (
        <li
          className={classNames(style.air__menuTop__item, {
            [style.air__menuTop__item__active]: activeItem === key,
          })}
          key={key}
        >
          {item.url && (
            <Link to={url} className={style.air__menuTop__link}>
              {icon && <i className={`${icon} ${style.air__menuTop__icon}`} />}
              <span>{title}</span>
            </Link>
          )}
          {!item.url && (
            <a href="_blank" onClick={e => e.preventDefault()} className={style.air__menuTop__link}>
              {icon && <i className={`${icon} ${style.air__menuTop__icon}`} />}
              <span>{title}</span>
            </a>
          )}
        </li>
      )
    }

    const submenuItem = item => {
      // console.log("===>Sub Menu Item<====",item)
      return (
        <li
          className={classNames(style.air__menuTop__item, style.air__menuTop__submenu, {
            [style.air__menuTop__submenu__active]: activeSubmenu === item.key,
          })}
          key={item.key}
        >
          <a
            href="_blank"
            className={style.air__menuTop__link}
            onClick={e => this.handleSubmenuClick(e, item.key)}
            // onClick={this.subMenuClick}
            onMouseEnter={event => this.handleFlyoutOver(event, item.key, item.children)}
            onFocus={event => this.handleFlyoutOver(event, item.key, item.children)}
            onMouseLeave={() => this.handleFlyoutOut(item.key)}
            onBlur={() => this.handleFlyoutOut(item.key)}
          >
            <i className={`${item.icon} ${style.air__menuTop__icon}`} />
            <span>{item.title}</span>
            {item.count && (
              <span className="badge text-white bg-blue-light float-right mt-1 px-2">
                {item.count}
              </span>
            )}
          </a>
          <ul className={style.air__menuTop__list}>
            {item.children.map(sub => {
              if (sub.children) {
                // console.log("===>Inside children<===")
                return submenuItem(sub)
              }
              return menuItem(sub)
            })}
          </ul>
        </li>
      )
    }

    return menuDataFromService.map(item => {
      if (item.children) {
        return submenuItem(item)
      }
      return menuItem(item)
    })
  }

  getMenuData=()=>{
    const {menuDataFromService} =this.state
    // console.log("MenuData===>",menuData);
    const menuDataArray=[]
    for (let index = 0; index < menuDataFromService.length; index+=1) {
      const element = menuDataFromService[index];
      const childData=element.children;
      if(childData!==undefined){
        for (let index1 = 0; index1 < childData.length; index1+=1) {
          // console.log("===>Element<====",childData[index1])
          menuDataArray.push({
            name:childData[index1].title,
            value:childData[index1].key,
            path:childData[index1].url
          })
        }
      }
      
    }
    // console.log("menuDataArray====>",menuDataArray)
    this.setState({menuDataInState:menuDataArray})

  }

  getSelectedMenuDetails=(value)=>{
    // console.log("Value is==>",value)
     const{history} = this.props;
     history.push(value)
  }

  onNOTONLogoClick=()=>{
    const{history} = this.props;
    history.push('/noton/homemenu')
  }

  render() {
    const { settings } = this.props
    // console.log("MenuData===>",menuData)
    const { renderedFlyoutItems,menuDataInState } = this.state
    const items = this.generateMenuItems()

    /* for (let index = 0; index < items.length; index+=1) {
      console.log("===>Items are<====",items[index])
      
    } */

    const menuList = menuDataInState.map(data => (
      <Option key={data.value} title={data.name} value={data.path}>
        {data.name}
      </Option>
    ))

    return (
      <div>
        <TransitionGroup>
          {Object.keys(renderedFlyoutItems).map(item => {
            return (
              <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
                {renderedFlyoutItems[item]}
              </CSSTransition>
            )
          })}
        </TransitionGroup>
        <div
          className={classNames(style.air__menuTop, {
            [style.air__menuTop__mobileToggled]: settings.isMobileMenuOpen,
            [style.air__menuTop__shadow]: settings.isMenuShadow,
            [style.air__menuTop__flyout]: true,
            [style.air__menuTop__blue]: settings.menuColor === 'blue',
            [style.air__menuTop__white]: settings.menuColor === 'white',
            [style.air__menuTop__gray]: settings.menuColor === 'gray',
            [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
            [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
            [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
          })}
        >
          <div className={style.topbar}>
            <div className="mr-md-4 mr-auto">
              <a
                href="_blank"
                onClick={e => e.preventDefault()}
                className={style.air__menuTop__logo}
              >
                <img src="resources/images/Noton-Final-4.png" alt="noton" role="presentation" height="30px" onClick={this.onNOTONLogoClick}  />
              </a>
            </div>
            <div className="mr-auto d-none d-md-block" />
            <div className="mb-0 mr-8 d-xl-block d-none">
              <Select
                placeholder="Search..."
                showSearch
                dropdownMatchSelectWidth={false}
                style={{ width: '400px' }}
                allowClear
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onFocus={this.getMenuData}
                onSelect={this.getSelectedMenuDetails}
              >
                {menuList}
              </Select>
            </div>
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block" />
            <div className="mr-4 d-none d-sm-block">
              <SettingOutlined style={{ fontSize: 18, color: '#909090' }} />
            </div>
            <div className="mr-4 d-none d-sm-block">
              <BellOutlined style={{ fontSize: 18, color: '#909090' }} />
            </div>
            <div>
              <UserMenu />
            </div>
          </div>
          <div className={style.air__menuTop__outer}>
            <a
              href="_blank"
              className={style.air__menuTop__mobileToggleButton}
              onClick={this.toggleMobileMenu}
            >
              <span />
            </a>
            <a
              href="_blank"
              onClick={e => e.preventDefault()}
              className={style.air__menuTop__logo}
            />
            {/* <img src="resources/images/notonlogo.png" alt="noton" height="30px" />
            </a> */}

            <div
              id="menu-left-container"
              className={style.air__menuTop__container}
              style={{ marginLeft: '-165px' }}
            >
              <ul className={style.air__menuTop__list}>
                {/* <li className={style.air__menuTop__item}>
                  <a href="#" className={style.air__menuTop__link} onClick={this.toggleSettings}>
                    <i className={`fe fe-settings ${style.air__menuTop__icon}`} />
                    <span>Settings</span>
                  </a>
                </li> */}
                {/* <li className={style.air__menuTop__item}>
                  <a
                    href="https://docs.airuitemplate.com/"
                    className={style.air__menuTop__link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={`fe fe-compass ${style.air__menuTop__icon}`} />
                    <span>Documentation</span>
                  </a>
                </li> */}

                {items}
              </ul>
            </div>
          </div>
        </div>
        <a href="_blank" className={style.air__menuTop__backdrop} onClick={this.toggleMobileMenu} />
      </div>
    )
  }
}

export default withRouter(MenuTop)
